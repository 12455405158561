import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { workerCategoryLabels } from '@octopus/contract-types';
import { UI_TYPE, nonEmptyString } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import {
  getOptionsFromCompanyContext,
  getOptionsFromList,
} from '../inputUtils';
import {
  AdmissionFormState,
  FormStepDefinition,
  getEnabledWorkerCategories,
} from '../types';

const getFieldDefinition = ({
  companyContext,
  formState,
  estagiarioEnabled,
}: {
  companyContext: CompanyContext;
  formState: AdmissionFormState;
  estagiarioEnabled: boolean;
}) => {
  const { workerCategory, legalEntity, admissionType } = formState;

  const ENABLED_WORKER_CATEGORIES = getEnabledWorkerCategories({
    estagiarioEnabled,
  });

  return [
    {
      label: 'Qual a modalidade de contratação',
      type: workerCategory != null ? z.string().optional() : nonEmptyString,
      name: 'workerCategory',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione a modalidade',
      disabled: workerCategory != null,
      options: getOptionsFromList({
        list: Object.entries(workerCategoryLabels).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: value }),
          {},
        ),
        currentValue: workerCategory,
        enabledKeys: ENABLED_WORKER_CATEGORIES,
      }),
    },
    {
      label: 'Qual o local de serviço',
      type: nonEmptyString,
      name: 'legalEntity',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o local de serviço',
      options: getOptionsFromCompanyContext({
        baseContext: companyContext?.legalEntities,
        currentValue: legalEntity,
        selectIfOnlyOne: true,
      }),
    },
    {
      label: 'Tipo de admissão',
      type: admissionType != null ? z.string().optional() : nonEmptyString,
      name: 'admissionType',
      disabled: admissionType != null,
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de admissão',
      options: [
        {
          value: 'admin_fills',
          selected: admissionType != null && admissionType === 'admin_fills',
          label: 'Preencher os dados',
        },
        {
          value: 'send_invite',
          selected: admissionType === 'send_invite',
          label: 'Envio de convite',
        },
      ],
    },
  ];
};
export const getModalidadeContratoFields = ({
  companyContext,
  formState,
  modalidadeFormSteps,
  estagiarioEnabled,
}: {
  companyContext: CompanyContext;
  formState: AdmissionFormState;
  estagiarioEnabled: boolean;
  modalidadeFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    companyContext,
    formState,
    estagiarioEnabled,
  }),
  options: {
    id: admissionDraftFormSteps.modalidade_contrato,
    title: 'Modalidade e local de serviço',
    completed: modalidadeFormSteps?.completed,
    reviewed: modalidadeFormSteps?.reviewed,
    hideLeftMenu: formState.legalEntity == null,
  },
});
