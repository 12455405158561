import { ZodObject, ZodRawShape, z } from 'zod';

const ContractTypesSchema = z.object({
  clt: z.union([z.boolean(), z.number().int().array()]),
  pj: z.boolean(),
});

const ApplicationVisibilitySchema = z.object({
  contract: z.boolean(),
  admission: z.boolean(),
});

const UserVisibilitySchema = z.object({
  employee: z.boolean(),
  support: z.boolean(),
  admin: z.boolean(),
});

export const CustomFieldNameSchema = z.string().regex(/^[A-Za-z]+$/);

const CustomFieldConfigSchema = z.object({
  name: CustomFieldNameSchema,
  label: z.string().min(1),
  visibility: z.object({
    user: UserVisibilitySchema,
    app: ApplicationVisibilitySchema,
  }),
  contractTypes: ContractTypesSchema,
});

const makeFieldsOptional = <T extends ZodRawShape>(schema: ZodObject<T>) => {
  const optionalFields = Object.entries(schema.shape).reduce(
    (acc, [prop, propSchema]) => ({
      ...acc,
      [prop]: propSchema.optional(),
    }),
    {},
  );
  return z.object(optionalFields);
};

const CustomFieldConfigUpdateSchema = z
  .object({
    label: z.string().min(1).optional(),
    visibility: z
      .object({
        user: makeFieldsOptional(UserVisibilitySchema).optional(),
        app: makeFieldsOptional(ApplicationVisibilitySchema).optional(),
      })
      .optional(),
    contractTypes: makeFieldsOptional(ContractTypesSchema).optional(),
  })
  .optional();

const TextFieldSchema = z.object({
  type: z.literal('text'),
  mask: z.array(z.string().min(1)),
});

const LongTextFieldSchema = z.object({
  type: z.literal('longtext'),
});

export const CustomFieldCreateSchema = z
  .union([TextFieldSchema, LongTextFieldSchema])
  .and(CustomFieldConfigSchema);

export const CustomFieldUpdateSchema = z
  .union([
    TextFieldSchema.omit({ type: true }).optional(),
    LongTextFieldSchema.omit({ type: true }).optional(),
  ])
  .and(CustomFieldConfigUpdateSchema)
  .and(
    z.object({
      version: z.number().int(),
    }),
  );
