import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Submission } from '@conform-to/react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import {
  AdmissionDraftEntry,
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormStepEnum,
  MembershipTypes,
  fetchAdmissionDraftPost,
  fetchApproveAdmissionDraftReview,
  fetchGetAllAdmissionDraftAttachments,
  fetchPostAdmissionInvite,
  fetchPutAdmissionDraft,
  fetchSubmitAdmissionDraftReview,
} from '@octopus/api';
import {
  WorkerCategoryType,
  getContractTypeFromWorkerCategory,
} from '@octopus/esocial/contracts';
import { AppError, translate } from '@octopus/i18n';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { SnackbarType } from '../../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { useMultiStepView } from '../../../modules/navigation/multiStep/useMultiStepView';
import {
  CompanyContext,
  MembershipContext,
  MembershipOption,
} from '../../../modules/types';
import { translateAPIErrors } from '../../../utils/errors';
import { useFFlags } from '../../fflags';
import { LoadingScene } from '../components/AdmissionDraftLoadingScene';
import { MissingFieldsDialog } from '../components/MissingFieldsDialog';
import { WorkerSubmitAdmissionDialog } from '../components/WorkerSubmitAdmissionDialog';

import { documentSections } from './documents/types';
import {
  getCreateBody,
  getHeaders,
  getStartingStep,
  getUpdateBody,
} from './form/fetchUtils';
import { getAdminSteps, getWorkerSteps } from './form/formStepInputs';
import { getFormStateFromEntry } from './form/mappers';
import { AdmissionFormState, AdmissionFormSteps } from './form/types';
import { MissingReviewsDialog } from './MissingReviewsDialog';
import { NewAdmissionContainer } from './NewAdmissionContainer';

type Props = {
  organizationId: string;
  companyId: string;
  companyContext: CompanyContext;
  admissionDraftEntry?: AdmissionDraftEntry;
  draftId?: string;
  reviewMode: boolean;
  finishReview: boolean;
  membershipProps: {
    membershipType: MembershipTypes;
    membership: MembershipContext;
    membershipOptions: MembershipOption[];
  };
};

const SAVE_AND_CLOSE_BUTTON_ID = 'save_and_close_BUTTON_ID_button';

const error_snackbar: SnackbarType = {
  isOpen: true,
  variant: 'error',
  Message:
    'Ocorreu um erro. Por favor tente novamente ou contacte o suporte da Tako.',
  StartAdornment: <Cancel />,
  autoHideDuration: 5000,
  hasCloseAction: true,
};

const getSuccessSnackbar = (message: string): SnackbarType => ({
  isOpen: true,
  variant: 'default',
  Message: message,
  StartAdornment: <CheckCircle />,
  autoHideDuration: 5000,
  hasCloseAction: false,
});

export function AdmissionDraftInputPage({
  organizationId,
  companyId,
  companyContext,
  admissionDraftEntry,
  draftId,
  membershipProps,
  reviewMode,
  finishReview,
}: Props) {
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();
  const { fflags } = useFFlags();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formSteps, setFormSteps] = useState<AdmissionFormSteps>({});
  const [adminFormSteps, setAdminFormSteps] = useState<AdmissionFormSteps>({});
  const [workerFormSteps, setWorkerFormSteps] = useState<AdmissionFormSteps>(
    {},
  );
  const [formState, setFormState] = useState<AdmissionFormState>({});
  const [fieldStepsState, setFieldStepsState] =
    useState<AdmissionDraftInputFormInfo>(null);
  const [oneTimeRedirect, setOneTimeRedirect] = useState<boolean>(false);
  const [openMissingFieldsDrawer, setOpenMissingFieldsDrawer] =
    useState<boolean>(false);
  const [openMissingReviewsDialog, setOpenMissingReviewsDialog] =
    useState<boolean>(false);
  const [openWorkerSubmitAdmissionDialog, setOpenWorkerSubmitAdmissionDialog] =
    useState<boolean>(false);

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const isWorkerExperience = membershipProps.membershipType === 'internal';
  const probationPeriodEnabled = fflags.isProbationPeriodVisible.enabled;

  useEffect(() => {
    if (draftId != null && draftId === admissionDraftEntry?.draftId) {
      const newFormState = getFormStateFromEntry({ admissionDraftEntry });
      setFormState(newFormState);
      setFieldStepsState({ ...admissionDraftEntry?.formInfo });
    }
  }, [draftId, admissionDraftEntry]);

  useEffect(() => {
    if (draftId && Object.keys(formState).length === 0) {
      return;
    }

    const estagiarioEnabled = fflags.estagiarioAdmissionEnabled.enabled;
    const adminFills =
      formState.admissionType != null
        ? formState.admissionType === 'admin_fills'
        : true;

    const adminSteps: AdmissionFormSteps = !isWorkerExperience
      ? getAdminSteps({
          draftStatus: admissionDraftEntry?.draftStatus,
          formState,
          companyContext,
          formSteps: fieldStepsState?.formSections?.['payroll_data'] ?? {},
          isWorkerExperience,
          probationPeriodEnabled,
          estagiarioEnabled,
        })
      : ({} as AdmissionFormSteps);
    setAdminFormSteps(adminSteps);

    const workerSteps: AdmissionFormSteps =
      adminFills || isWorkerExperience
        ? getWorkerSteps({
            draftStatus: admissionDraftEntry?.draftStatus,
            formState,
            formSteps: fieldStepsState?.formSections?.['personal_data'] ?? {},
            isWorkerExperience,
          })
        : ({} as AdmissionFormSteps);
    setWorkerFormSteps(workerSteps);

    const steps: AdmissionFormSteps = {
      ...adminSteps,
      ...workerSteps,
    };
    setFormSteps(steps);
  }, [
    companyContext,
    formState,
    draftId,
    fieldStepsState,
    admissionDraftEntry,
    isWorkerExperience,
    fflags.estagiarioAdmissionEnabled.enabled,
    probationPeriodEnabled,
  ]);

  const multiStepView = useMultiStepView<string>(formSteps);

  useEffect(() => {
    if (oneTimeRedirect || !draftId || Object.keys(formSteps).length <= 0) {
      return;
    }

    const startingStep = getStartingStep({
      steps: multiStepView.steps,
      reviewMode,
      countAdminSteps: Object.keys(adminFormSteps).length,
      finishReview,
    });
    if (startingStep != null) {
      setOneTimeRedirect(true);
      if (multiStepView.currentStep?.isFirst && !isWorkerExperience) {
        multiStepView.goTo(startingStep);
      }
    }
  }, [formSteps, draftId, oneTimeRedirect, multiStepView, isWorkerExperience]);

  const getCurrentStepName = () =>
    multiStepView.currentStep?.name as AdmissionDraftInputFormStepEnum;

  const onStepSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => {
    const shouldClose =
      (event.nativeEvent as SubmitEvent).submitter.id ===
      SAVE_AND_CLOSE_BUTTON_ID;
    const newFormState = { ...formState, ...formData.payload };

    const currentStepName = getCurrentStepName();
    switch (currentStepName) {
      // empty steps - we don't save the information on these initial steps
      case admissionDraftFormSteps.modalidade_contrato:
      case admissionDraftFormSteps.criacao_usuario:
        if (draftId) {
          updateAdmissionDraft({
            currentStepName,
            newFormState,
            shouldClose,
          });
        } else {
          forceCompleteCurrentStep();
          setFormState(newFormState);
        }
        shouldClose ? closeFormPage() : multiStepView.goForward();
        break;

      case admissionDraftFormSteps.profissional: {
        if (!draftId) {
          createAdmissionDraft({ currentStepName, newFormState });
        } else {
          updateAdmissionDraft({
            currentStepName,
            newFormState,
            shouldClose,
          });
        }
        break;
      }

      default: {
        updateAdmissionDraft({ currentStepName, newFormState, shouldClose });
        break;
      }
    }
  };

  const forceCompleteCurrentStep = () => {
    const currentStepName = getCurrentStepName();
    setFieldStepsState({
      formSections: {
        payroll_data: {
          ...fieldStepsState?.formSections['payroll_data'],
          [currentStepName]: {
            section: 'payroll_data',
            step: currentStepName,
            completed: true,
          },
        },
      },
    });
  };

  const getNotReviwedSteps = () =>
    Object.values(fieldStepsState.formSections.personal_data).filter(
      (step) => !step.reviewed && step.step !== 'finaliza_admissao',
    );

  const onSubmitCustom = ({
    stepName,
    extraData,
    ignoreSteps,
  }: {
    stepName?: AdmissionDraftInputFormStepEnum;
    extraData?: Record<string, any>;
    ignoreSteps?: boolean;
  }) => {
    const currentStepName = stepName || getCurrentStepName();
    let newFormState = { ...formState };
    switch (currentStepName) {
      case admissionDraftFormSteps.envio_convite: {
        sendInvite({ currentStepName, newFormState });
        break;
      }
      case admissionDraftFormSteps.dependentes: {
        newFormState = { ...newFormState, ...extraData };
        return updateAdmissionDraft({
          currentStepName,
          newFormState,
        });
      }
      case admissionDraftFormSteps.finaliza_admissao: {
        if (isWorkerExperience && !ignoreSteps) {
          const allSteps = Object.keys(
            fieldStepsState.formSections.personal_data,
          ).length;
          const completedSteps = Object.values(
            fieldStepsState.formSections.personal_data,
          ).filter((step) => step.completed).length;
          if (allSteps !== completedSteps) {
            return setOpenMissingFieldsDrawer(true);
          }
        }

        if (admissionDraftEntry?.draftStatus === 'admission_submitted') {
          const notReviewedSteps = getNotReviwedSteps();

          if (notReviewedSteps.length > 0) {
            return setOpenMissingReviewsDialog(true);
          }
        }

        finalizeAdmission();

        break;
      }
      default: {
        throw new Error(
          `onSubmitCustom not implemented for step ${currentStepName}`,
        );
      }
    }
  };

  const finalizeAdmission = () => {
    const currentStepName = 'finaliza_admissao';
    const newFormState = { ...formState };
    updateAdmissionDraft({
      currentStepName,
      newFormState,
      successCallback: (admissionDraftEntry) => {
        if (admissionDraftEntry?.draftStatus !== 'admission_submitted') {
          submitAdmissionToApproval();
        } else {
          approveAdmissionDraft();
        }
      },
    });
  };

  const onSubmitCustomCallback = () => {
    const currentStepName = getCurrentStepName();

    switch (currentStepName) {
      case admissionDraftFormSteps.documentos: {
        setIsLoading(true);
        return fetchGetAllAdmissionDraftAttachments({
          pathParams: {
            organizationId: organizationId ?? '',
            draftId: draftId ?? '',
          },
        })
          .then((attachments) => {
            const requiredSectionsWithoutFile = Object.entries(
              documentSections,
            ).filter(
              ([key, value]) =>
                value.required &&
                !attachments.find((att) => att.formStep === key),
            );

            if (requiredSectionsWithoutFile.length > 0) {
              handleError(
                null,
                'Por favor envie o arquivo nas seções obrigatórias (com asterisco).',
              );
              return;
            }

            return updateAdmissionDraft({
              currentStepName,
              newFormState: { ...formState },
              successCallback: () => {
                if (isWorkerExperience) {
                  if (isSmallDevice) {
                    navigate(`/admissions/new/${draftId}`);
                    window.location.reload();
                  } else {
                    onSubmitCustom({
                      stepName: admissionDraftFormSteps.finaliza_admissao,
                      ignoreSteps: true,
                    });
                  }
                }
              },
            });
          })
          .catch((error) => {
            handleError(error);
          });
      }
      default: {
        throw new Error(
          `onSubmitCustomCallback not implemented for step ${currentStepName}`,
        );
      }
    }
  };

  const handleError = (error?: AppError, message?: string) => {
    const errors = translateAPIErrors(error);
    const errorJoined = errors ? errors.join('; \n') + '.' : null;

    let errorMessage = errorJoined ?? error_snackbar.Message;

    if (error?.message?.match('Exclusive resource')) {
      if (error?.message?.match('workerId')) {
        errorMessage = translate('ADM00001', 'pt');
      } else if (error?.message?.match('matricula')) {
        errorMessage = translate('ADM00002', 'pt');
      }
    }

    setIsLoading(false);
    showSnackbar({ ...error_snackbar, Message: message ?? errorMessage });
    console.error(error);
  };

  const submitAdmissionToApproval = () => {
    setIsLoading(true);
    const body = {
      contractType: getContractType(formState.workerCategory),
    };

    fetchSubmitAdmissionDraftReview({
      pathParams: {
        organizationId,
        draftId,
      },
      body,
    })
      .then((draft) => {
        const newState = getFormStateFromEntry({
          admissionDraftEntry: draft,
        });
        setFormState(newState);
        setIsLoading(false);
        if (!isWorkerExperience) {
          showSnackbar(getSuccessSnackbar('Admissão submetida para revisão.'));
          closeFormPage();
        } else {
          setOpenWorkerSubmitAdmissionDialog(true);
        }
      })
      .catch(handleError);
  };

  const getContractType = (workerCategory: string) =>
    getContractTypeFromWorkerCategory(
      workerCategory as WorkerCategoryType,
    ) as 'br:clt';

  const approveAdmissionDraft = () => {
    setIsLoading(true);
    const body = {
      contractType: getContractType(formState.workerCategory),
    };

    fetchApproveAdmissionDraftReview({
      pathParams: {
        organizationId,
        draftId,
      },
      body,
    })
      .then((draft) => {
        const newState = getFormStateFromEntry({
          admissionDraftEntry: draft,
        });
        setFormState(newState);
        setIsLoading(false);
        showSnackbar(getSuccessSnackbar('Admissão concluída.'));
        closeFormPage();
      })
      .catch(handleError);
  };

  const updateAdmissionDraft = ({
    currentStepName,
    newFormState,
    disableMoveToNextStep = false,
    shouldClose = false,
    successCallback,
  }: {
    currentStepName: AdmissionDraftInputFormStepEnum;
    newFormState: AdmissionFormState;
    disableMoveToNextStep?: boolean;
    shouldClose?: boolean;
    successCallback?: (admissionDraftEntry: AdmissionDraftEntry) => void;
  }) => {
    setIsLoading(true);
    const body = getUpdateBody({
      previousFormInfo: fieldStepsState,
      formState: newFormState,
      adminFormSteps,
      workerFormSteps,
      currentStepName,
      reviewMode,
    });
    const headers = getHeaders();

    fetchPutAdmissionDraft({
      pathParams: {
        organizationId,
        draftId,
      },
      body,
      headers,
    })
      .then((draft) => {
        const newState = getFormStateFromEntry({
          admissionDraftEntry: draft,
        });
        setFieldStepsState(draft?.formInfo);

        setFormState(newState);
        setIsLoading(false);
        if (shouldClose) {
          showSnackbar(getSuccessSnackbar('O candidato foi salvo'));
          closeFormPage();
        } else if (draftId === draft.draftId && !disableMoveToNextStep) {
          multiStepView.goForward();
        }

        successCallback && draft && successCallback(draft);
      })
      .catch(handleError);
  };

  const createAdmissionDraft = ({
    currentStepName,
    newFormState,
  }: {
    currentStepName: AdmissionDraftInputFormStepEnum;
    newFormState: AdmissionFormState;
  }) => {
    setIsLoading(true);
    const body = getCreateBody({
      previousFormInfo: fieldStepsState,
      companyId,
      formState: newFormState,
      adminFormSteps,
      workerFormSteps,
      currentStepName,
    });
    const headers = getHeaders();

    fetchAdmissionDraftPost({
      pathParams: {
        organizationId,
      },
      body,
      headers,
    })
      .then((draft) => {
        setIsLoading(false);
        const nextStep = multiStepView.currentStep.idx + 1;
        navigate(`/admissions/new/${draft.draftId}?msvnavpos=${nextStep}`);
      })
      .catch(handleError);
  };

  const sendInvite = ({
    currentStepName,
    newFormState,
  }: {
    currentStepName: AdmissionDraftInputFormStepEnum;
    newFormState: AdmissionFormState;
  }) => {
    setIsLoading(true);
    fetchPostAdmissionInvite({
      pathParams: {
        organizationId,
      },
      body: {
        companyId,
        contractType: getContractType(formState.workerCategory),
        workerName: formState.user_name,
        workerEmail: formState.emailCorp || formState.user_email,
        draftId,
      },
    })
      .then(() => {
        updateAdmissionDraft({
          currentStepName,
          newFormState,
          shouldClose: true,
        });
        showSnackbar(getSuccessSnackbar('O candidato foi convidado'));
      })
      .catch(handleError);
  };

  const closeFormPage = () => {
    if (!isWorkerExperience) {
      navigate('/admissions');
      return;
    }

    window.location.reload();
  };

  if (
    draftId &&
    (Object.keys(formState).length === 0 ||
      Object.keys(formSteps).length === 0 ||
      !oneTimeRedirect)
  ) {
    return <LoadingScene />;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
      }}
      display={'flex'}
      flexDirection={'column'}
    >
      <NewAdmissionContainer
        isWorkerExperience={isWorkerExperience}
        draftId={draftId}
        organizationId={organizationId}
        companyId={companyId}
        draftStatus={admissionDraftEntry?.draftStatus}
        saveAndCloseButtonId={SAVE_AND_CLOSE_BUTTON_ID}
        isSmallDevice={isSmallDevice}
        isLoading={isLoading}
        adminFormSteps={adminFormSteps}
        workerFormSteps={workerFormSteps}
        formSteps={formSteps}
        formState={formState}
        multiStepView={multiStepView}
        onStepSubmit={onStepSubmit}
        onSubmitCustom={onSubmitCustom}
        onSubmitCustomCallback={onSubmitCustomCallback}
        getCurrentStepName={getCurrentStepName}
        updateAdmissionDraft={updateAdmissionDraft}
        forceCompleteCurrentStep={forceCompleteCurrentStep}
        closeFormPage={closeFormPage}
        membershipProps={membershipProps}
        reviewMode={reviewMode}
      />

      <MissingFieldsDialog
        open={openMissingFieldsDrawer}
        setOpen={setOpenMissingFieldsDrawer}
        isSmallDevice={isSmallDevice}
      />

      <MissingReviewsDialog
        open={openMissingReviewsDialog}
        setOpen={setOpenMissingReviewsDialog}
        confirmClick={finalizeAdmission}
        isLoading={isLoading}
      />

      <WorkerSubmitAdmissionDialog
        open={openWorkerSubmitAdmissionDialog}
        setOpen={setOpenWorkerSubmitAdmissionDialog}
        isSmallDevice={isSmallDevice}
      />
    </Box>
  );
}
