const ContractErrorCodes = {
  CTR00001: 'br.pessoa is required when country code is BR',
  CTR00002: 'nascimento is required',
  CTR00003: 'tmpResid and condIng must be null when paisNac is 105',
  CTR00004: 'tmpResid and condIng must be filled when paisNac is not 105',
  CTR00005: 'br.endereco is required when country code is BR',
  CTR00006: 'br.deficiencia is required when country code is BR',
  CTR00007: 'invalid CPF for dependent',
  CTR00008: 'nmFuncao is required when tpRegTrab is 2',
  CTR00009: 'CBOFuncao is required when nmFuncao is set',
  CTR00010: 'CBOFuncao cannot be set if nmFuncao is empty',
  CTR00011: 'estagio is required if codCateg is 901 or 906',
  CTR00012: 'estagio cannot be set if codCateg is not 901 or 906',
  CTR00013: `natEstagio must be "N" if codCateg is 906`,
  CTR00014: 'nivEstagio is required if codCateg is 901',
  CTR00015: 'nivEstagio cannot be set to 9 if codCateg is 906',
  CTR00016: 'dtPrevTerm must be after dtAdm',
  CTR00017: 'cnpjAgntInteg must be a valid CNPJ',
  CTR00018: 'cpfSupervisor must be a valid CPF',
  CTR00019: 'cnpjInstEnsino must be a valid CNPJ',
  CTR00020: 'tpRegTrab must be 1 if codCateg is 104',
  CTR00021: 'tpRegPrev must be 1 if codCateg is 104',
  CTR00022: 'tpRegPrev cannot be 2 if codCateg is < 200',
  CTR00023: 'regime is required if tpRegTrab is 1',
  CTR00024: 'regime must not be set if tpRegTrab is not 1',
  CTR00025: 'nrProcTrab must be set if indAdmissao is 3',
  CTR00026: 'nrProcTrab must not be set if indAdmissao is not 3',
  CTR00027: 'qtdHrsSem must be set if codCateg is not 111',
  CTR00028: 'horNoturno must be set if codCateg is not 111',
  CTR00029: 'remuneracao must be set if tpRegTrab is 1',
  CTR00030: 'remuneracao must not be set if tpRegTrab is 2',
  CTR00031: 'vrSalFx must be 0 if undSalFixo is 7',
  CTR00032: 'dscSalVar must be set if undSalFixo is 6 or 7',
  CTR00033: 'duracao must be set if tpRegTrab is 1',
  CTR00034: 'duracao must not be set if tpRegTrab is 2',
  CTR00035: 'dtTerm must not be set if tpContr is 1',
  CTR00036: 'clauAssec must not be set if tpContr is 1',
  CTR00037: 'dtTerm must be set if tpContr is 2',
  CTR00038: 'objDet must be set if tpContr is 3',
  CTR00039: 'clauAssec must be set if tpContr is not 1',
  CTR00040: 'dtTerm must be equal or after dtAdm',
  CTR00041: 'dtPerExpProrTerm can only exist with dtPerExpTerm set',
  CTR00042: 'dtPerExpProrTerm must be after dtAdm dtPerExpTerm',
  CTR00043: 'sucessao must be set if tpAdmissao is 2, 3, 4 or 7',
  CTR00044: 'nrInsc must be a valid CNPJ if tpInsc is 1',
  CTR00045: 'nrInsc must be a valid CPF if tpInsc is 2',
  CTR00046: 'nrInsc must be a valid CEI if tpInsc is 6',
  CTR00047: 'dtTransf must be after dtAdm',
  CTR00048:
    'tpInsc cant be 6 unless dtTransf must be equal or before 2011-12-31',
  CTR00049:
    'tpInsc cant be 5 unless dtTransf must be equal or before 1999-06-30',
  CTR00050: 'br is required',
  CTR00051: 'contract must be clt',
  CTR00052: 'requiredField',
  CTR00053: 'remuneracao is required for CLT',
  CTR00054: 'remuneracao must not be set for CLT autônomo [701, 711, 712]',
  CTR00055: 'tpRegPrev is required for CLT:geral',
  CTR00056: 'jornada must not be set for CLT autônomo [701, 711, 712]',
  CTR00057: 'estagio must not be set for CLT autônomo [701, 711, 712]',
  CTR00058: 'duracao must not be set for CLT autônomo [701, 711, 712]',
  CTR00059: 'jornada is required for CLT',
  CTR00060: 'duracao is required for CLT',
  CTR00061: 'vinculo is required for CLT',
  CTR00062: 'Admission date is required',
  CTR00063:
    'Fields [tpAdmissao, indAdmissao, tpRegJor, natAtividade] are required',
  CTR00064: 'tpRegTrab is required for CLT:geral',

  CTR00065: 'descrDep is required when tpDep is 99',
  CTR00066: 'descrDep is allowed only if tpDep is 99',
  CTR00067: 'invalid contract type. Must be br:clt',
  CTR00068: 'matricula is required',
  CTR00069: 'dtOpcFGTS is required for employee with codCateg=721',
  CTR00070: 'invalid contract type. Must be br:pj',
  CTR00071: 'contract not found',
  CTR00072: 'contract is terminated',
  CTR00073: 'contract cannot be assigned as its own manager',
  CTR00074: 'contract is already assigned to manager on given effective date',
  CTR00075: 'manager contract not found',
  CTR00076: 'manager contract is terminated',
  CTR00077:
    'cannot assign a manager that would cause a circular structure in the org chart',
  CTR00078: 'contract is not active',
  CTR00079: 'cannot transfer to the same company',
  CTR00080: 'contract event not found',
  CTR00081: 'event type cannot be deleted',
  CTR00082: 'contract admission event cannot be found',
  CTR00083: 'cannot delete contract event for company with eSocial enabled',
  CTR00084: 'leave not found',
  CTR00085: "can't delete leave if startDate is before last leave",
  CTR00086: 'cannot transfer to the same legal entity',
  CTR00087: "can't update leave if endDate is before startDate",
  CTR00088: "can't patch leave if startDate is before last leave",
  CTR00089: "can't register leave if endDate is before startDate",
  CTR00090: 'other reasons leave are not yet implemented',
  CTR00091: "can't register leave if startDate is before last leave",
  CTR00092: 'invalid leave br payload',
  CTR00093: "can't terminate contract before last change",
  CTR00094: 'duplicated dependents',
  CTR00095: 'invalid cpf for dependent',
  CTR00096: "vrSalFx's precision is higher then 2 decimmal places",
  CTR00097: 'novaMatricula is required for a CLT contract',
} as const;

export default ContractErrorCodes;
