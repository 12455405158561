import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Submission } from '@conform-to/react';

import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';

import {
  AdmissionDraftEntry,
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormStepEnum,
  fetchPutAdmissionDraft,
} from '@octopus/api';
import { formatDateTimeBR } from '@octopus/formatters';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { Navigator } from '../../../modules/components/Navigator';
import { SnackbarType } from '../../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { MultiStepView } from '../../../modules/navigation/multiStep/MultiStepView';
import { useMultiStepView } from '../../../modules/navigation/multiStep/useMultiStepView';
import { CompanyContext } from '../../../modules/types';
import { FilePreviewComponent } from '../../companies/preferences/fileComponents';
import { useFFlags } from '../../fflags';
import { LoadingScene } from '../components/AdmissionDraftLoadingScene';
import { documentReviewSections } from '../new/documents/types';
import {
  getDocumentSteps,
  getReviewDependentStep,
} from '../new/form/formStepInputs';
import { getFormStateFromEntry } from '../new/form/mappers';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepDefinition,
} from '../new/form/types';
import { AdmissionReviewDocumentsType } from '../utils/types';

import { AdmissionReviewDialogs } from './AdmissionReviewDialogs';
import { AdmissionReviewDocumentsForm } from './AdmissionReviewDocumentsForm';
import { getHeaders, getUpdateBody } from './form/fetchUtils';

type Props = {
  organizationId: string;
  companyId: string;
  companyContext: CompanyContext;
  admissionDraftEntry?: AdmissionDraftEntry;
  draftId: string;
  files: AdmissionReviewDocumentsType;
};

const error_snackbar: SnackbarType = {
  isOpen: true,
  variant: 'error',
  Message:
    'Ocorreu um erro. Por favor tente novamente ou contacte o suporte da Tako.',
  StartAdornment: <Cancel />,
  autoHideDuration: 5000,
  hasCloseAction: true,
};

export function AdmissionReviewDocuments({
  organizationId,
  companyId,
  companyContext,
  admissionDraftEntry,
  draftId,
  files,
}: Props) {
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const [file, setFile] = useState<File>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formSteps, setFormSteps] = useState<AdmissionFormSteps>({});
  const [formState, setFormState] = useState<AdmissionFormState>({});
  const [dialogs, setDialogs] = useState({
    startDialog: true,
    finishDialog: false,
  });
  const { fflags } = useFFlags();

  const [fieldStepsState, setFieldStepsState] =
    useState<AdmissionDraftInputFormInfo>(null);

  const getFormId = (stepName: string) =>
    draftId ? `${draftId}_${stepName}` : null;

  useEffect(() => {
    if (Object.keys(formState).length === 0 || !files) {
      return;
    }

    const steps = getDocumentSteps({
      draftStatus: admissionDraftEntry.draftStatus,
      formState,
      fieldStepsState,
      companyContext,
      probationPeriodEnabled: fflags.isProbationPeriodVisible.enabled,
      estagiarioEnabled: fflags.estagiarioAdmissionEnabled.enabled,
    });

    const relevantSteps = Object.keys(steps)
      .filter(
        (step) =>
          documentReviewSections[step as AdmissionDraftInputFormStepEnum]
            .required ||
          files[step as AdmissionDraftInputFormStepEnum]?.length > 0,
      )
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: steps[key as AdmissionDraftInputFormStepEnum],
        }),
        {} as Partial<Record<string, FormStepDefinition>>,
      );

    const dependenteFiles = files['dependentes'];
    if (dependenteFiles?.length > 0 && formState.dependentes?.length > 0) {
      for (let i = 0; i < formState.dependentes.length; i++) {
        const depId = formState.dependentes[i].id;
        if (dependenteFiles.find(({ dependentId }) => dependentId === depId)) {
          relevantSteps[`dependentes_${i}`] = getReviewDependentStep({
            fieldStepsState,
            dependentId: depId,
          });
        }
      }
    }

    setFormSteps(relevantSteps);
  }, [
    companyContext,
    formState,
    fieldStepsState,
    admissionDraftEntry?.draftStatus,
    files,
    fflags.isProbationPeriodVisible.enabled,
    fflags.estagiarioAdmissionEnabled.enabled,
  ]);

  const getCurrentStepName = () => {
    const stepData = multiStepView.currentStep?.data as FormStepDefinition;
    return stepData?.options?.id;
  };

  const getCurrentDependentId = () => {
    const stepData = multiStepView.currentStep?.data as FormStepDefinition;
    return stepData?.options?.dependentId;
  };

  const getCurrentStepTitle = () => {
    const stepData = multiStepView.currentStep?.data as FormStepDefinition;
    return stepData?.options?.title;
  };

  const multiStepView = useMultiStepView<string>(formSteps);

  const stepsNavigator = useMemo(
    () => ({
      canGoBackwards: !multiStepView.currentStep?.isFirst,
      canGoForward: !multiStepView.currentStep?.isLast,
      goBackwards: () => {
        setIsLoading(true);
        setFile(null);
        multiStepView.goBack();
      },
      goForward: () => {
        setIsLoading(true);
        setFile(null);
        multiStepView.goForward();
      },
    }),
    [multiStepView],
  );

  useEffect(() => {
    if (draftId === admissionDraftEntry?.draftId) {
      const newFormState = getFormStateFromEntry({ admissionDraftEntry });
      setFormState(newFormState);
      setFieldStepsState({ ...admissionDraftEntry?.formInfo });
    }
  }, [draftId, admissionDraftEntry]);

  useEffect(() => {
    if (!files) {
      setFile(null);
      return;
    }

    const currentStepName = getCurrentStepName();
    const currentStepFiles = files[currentStepName];

    const dependentId = getCurrentDependentId();
    if (currentStepFiles) {
      const currentFile = currentStepFiles.find(
        (f) =>
          (!f.dependentId && !dependentId) || f.dependentId === dependentId,
      )?.file;
      setFile(currentFile);
    }

    setIsLoading(false);
  }, [files, multiStepView.currentStep]);

  const onStepSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => {
    const newFormState = { ...formState, ...formData.payload };
    const currentStepName = getCurrentStepName();
    updateAdmissionDraft({ currentStepName, newFormState });
  };

  const onSubmitCustomCallback = (newFormState: AdmissionFormState) => {
    const currentStepName = getCurrentStepName();

    switch (currentStepName) {
      case admissionDraftFormSteps.dependentes: {
        return updateAdmissionDraft({
          currentStepName,
          newFormState,
        });
      }
      default: {
        throw new Error(
          `onSubmitCustomCallback not implemented for step ${currentStepName}`,
        );
      }
    }
  };

  const handleError = (error: Error) => {
    let errorMessage = error_snackbar.Message;
    if (
      error.message.match('Exclusive resource') &&
      error.message.match('workerId|matricula')
    ) {
      errorMessage = 'Matricula já utilizada, por favor escolha outro valor.';
    }
    setIsLoading(false);
    showSnackbar({ ...error_snackbar, Message: errorMessage });
    console.error(error);
  };

  const updateAdmissionDraft = ({
    currentStepName,
    newFormState,
  }: {
    currentStepName: AdmissionDraftInputFormStepEnum;
    newFormState: AdmissionFormState;
  }) => {
    setIsLoading(true);
    const body = getUpdateBody({
      previousFormInfo: fieldStepsState,
      formState: newFormState,
      currentStepName,
    });

    const headers = getHeaders();

    return fetchPutAdmissionDraft({
      pathParams: {
        organizationId,
        draftId,
      },
      body,
      headers,
    })
      .then((draft) => {
        const newState = getFormStateFromEntry({ admissionDraftEntry: draft });
        setFieldStepsState(draft?.formInfo);

        setFormState(newState);
        moveToNextStep();
        setIsLoading(false);
      })
      .catch(handleError);
  };

  const moveToNextStep = () => {
    if (stepsNavigator.canGoForward) {
      stepsNavigator.goForward();
    } else {
      setDialogs({ ...dialogs, finishDialog: true });
    }
  };

  const closeFormPage = () => {
    navigate('/admissions');
  };

  const showLastReviewed = useMemo(
    () =>
      Object.values(formSteps).find((step) => step.options.reviewed) != null,
    [formSteps],
  );

  if (
    Object.keys(formState).length === 0 ||
    Object.keys(formSteps).length === 0 ||
    files == null
  ) {
    return <LoadingScene />;
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            px: 7,
            py: 10,
            width: '50%',
          }}
        >
          <Box
            sx={{
              marginBottom: 2.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="h2">Modo de Revisão</Typography>
            </Box>
            <Navigator
              label=""
              arrowNavigation={stepsNavigator}
              arrowsDirection="vertical"
              iconSize="medium"
              noPadding={true}
            />
          </Box>

          <Typography variant="h4">{getCurrentStepTitle()}</Typography>
          <Divider sx={{ mt: 1, mb: 2.5 }}></Divider>
          <MultiStepView multiStepView={multiStepView}>
            {Object.entries(formSteps).map(([key, value]) => {
              const stepName = value.options
                .id as AdmissionDraftInputFormStepEnum;

              const isStepActive = stepName === getCurrentStepName();

              let children = null;
              if (isStepActive) {
                if (value.options.getCustomComponent != null) {
                  children = value.options.getCustomComponent({
                    updateDisabled: false,
                    formState,
                    formId: getFormId(value.options.id),
                    stepDefinition: value,
                    onSubmitCallback: onSubmitCustomCallback,
                    goToNextStep: () => {
                      throw new Error(
                        'goToNextStep: not implemented for AdmissionReview',
                      );
                    },
                    goToPreviousStep: () => {
                      throw new Error(
                        'goToPreviousStep: not implemented for AdmissionReview',
                      );
                    },
                    onSubmit: () => {
                      throw new Error(
                        'onSubmit: not implemented for AdmissionReview',
                      );
                    },
                    submitLabel: '',
                    isLoading,
                    organizationId,
                    companyId,
                    draftId,
                  });
                } else {
                  children = (
                    <AdmissionReviewDocumentsForm
                      onSubmit={onStepSubmit}
                      definition={value.definition}
                      id={getFormId(value.options.id)}
                    />
                  );
                }
              }

              return (
                <MultiStepView.Step name={key} key={key}>
                  {children}
                </MultiStepView.Step>
              );
            })}
          </MultiStepView>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.default',
            px: 7,
            py: 10,
            flexGrow: 1,
            width: '50%',
          }}
        >
          {file && (
            <FilePreviewComponent
              file={file}
              setFile={setFile}
              showDeleteButton={false}
            />
          )}
          {!file && (
            <Typography>Nenhum arquivo encontrado nesse passo</Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          border: 'none',
          position: 'fixed',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          boxSizing: 'border-box',
          gap: 1,
          bottom: 0,
          right: 0,
          backgroundColor: 'background.translucid',
          py: 1,
          px: 3,
        }}
      >
        {showLastReviewed && (
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ marginRight: 'auto', paddingLeft: 6.5 }}
          >
            Última revisão feita
            {admissionDraftEntry?.lastUpdatedByUsername != null
              ? ` por ${admissionDraftEntry?.lastUpdatedByUsername}`
              : ''}{' '}
            em {formatDateTimeBR(admissionDraftEntry?.lastUpdatedOn)}
          </Typography>
        )}
        <Box sx={{ mr: 1 }}>
          <Typography variant="caption" color="text.secondary">
            {(multiStepView.currentStep?.idx ?? 0) + 1} de{' '}
            {multiStepView.steps.length} documentos a serem revisados
          </Typography>
        </Box>
        <LoadingButton
          variant="outlined"
          color="secondary"
          onClick={closeFormPage}
          sx={{ px: 4, py: 1.25 }}
        >
          Cancelar
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color={multiStepView.currentStep?.isLast ? 'primary' : 'primaryAlt'}
          type="submit"
          // onClick={() =>
          //   multiStepView.currentStep?.isLast ? moveToNextStep() : undefined
          // }
          sx={{ px: 4, py: 1.25 }}
          form={`payload:${getFormId(multiStepView.currentStep?.name)}`}
        >
          {multiStepView.currentStep?.isLast ? 'Salvar revisão' : 'Avançar'}
        </LoadingButton>
        <AdmissionReviewDialogs
          startDialog={{
            open: dialogs['startDialog'],
            setOpen: (open: boolean) =>
              setDialogs({ ...dialogs, startDialog: open }),
            cancelClick: closeFormPage,
            confirmClick: () => setDialogs({ ...dialogs, startDialog: false }),
          }}
          finishDialog={{
            open: dialogs['finishDialog'],
            setOpen: (open: boolean) =>
              setDialogs({ ...dialogs, finishDialog: open }),
            cancelClick: () => {
              setDialogs({ ...dialogs, finishDialog: false });
              navigate(
                `/admissions/new/${draftId}?reviewMode=true&finishReview=true`,
              );
            },
            confirmClick: () => {
              navigate(`/admissions/new/${draftId}?reviewMode=true`);
              setDialogs({ ...dialogs, finishDialog: false });
            },
          }}
        />
      </Box>
    </Box>
  );
}
